export const stringToBoolean = (value: string | undefined) => {
    switch (value?.toLowerCase()) {
        case 'false':
            return false;
        case 'true':
            return true;
        default:
            return undefined;
    }
};
