import { DeepPartial } from '../object';

export const jsonParse = <T = unknown>(
    value: string | undefined | null,
    throwError?: boolean
): DeepPartial<T> | undefined => {
    try {
        return value ? (JSON.parse(value) as DeepPartial<T>) : undefined;
    } catch (error) {
        if (throwError) {
            throw error;
        }
        return undefined;
    }
};
