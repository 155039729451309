import { jsonStringify } from '@walley/language';

export const normalizeLogProperty = (value: unknown) => {
    switch (typeof value) {
        case 'function':
        case 'bigint':
        case 'symbol':
            return value.toString();
        case 'object':
            return jsonStringify(value);
        default:
            return value;
    }
};
