import { PlainObject } from './PlainObject';
import { isObject } from './isObject';

export const isPlainObject = <T extends PlainObject = PlainObject>(
    value: unknown
): value is T => {
    if (!isObject(value)) {
        return false;
    }

    const prototype = Object.getPrototypeOf(value);

    return (
        (prototype === null ||
            prototype === Object.prototype ||
            Object.getPrototypeOf(prototype) === null) &&
        !(Symbol.toStringTag in value) &&
        !(Symbol.iterator in value)
    );
};
