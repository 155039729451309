import { stringToNumber } from './stringToNumber';

export const parseNumber = (value: unknown, locale: string | undefined) => {
    switch (typeof value) {
        case 'string':
            return stringToNumber(value, locale);
        default:
            return undefined;
    }
};
