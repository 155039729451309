import { Version, toLogLevel } from '@walley/domain';
import { jsonStringify, toUri } from '@walley/language';

export const getConfigOrThrowException = () => {
    const apiHost = toUri(import.meta.env.WALLEY_API_HOST);
    const clientHost = toUri(import.meta.env.WALLEY_CLIENT_HOST);
    const commit = import.meta.env.WALLEY_COMMIT;
    const logLevelClient = toLogLevel(import.meta.env.WALLEY_LOG_LEVEL_CLIENT);
    const logLevelServer = toLogLevel(import.meta.env.WALLEY_LOG_LEVEL_SERVER);
    const version = import.meta.env.WALLEY_VERSION as Version;

    if (!(apiHost && clientHost && logLevelClient && logLevelServer)) {
        throw new Error(
            `Misconfigured ${jsonStringify({
                apiHost,
                clientHost,
                logLevelClient,
                logLevelServer,
            })}`
        );
    }

    return {
        apiHost,
        clientHost,
        commit,
        logLevelClient,
        logLevelServer,
        version,
    };
};
