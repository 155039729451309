import { toNumber } from '@walley/language';
import { setElementHeight } from '../../dom';
import { removeIntegrationSpinner } from '../removeIntegrationSpinner';
import { HandleMessageFunction } from './HandleMessageFunction';

export const handleHeightSetMessage: HandleMessageFunction = (
    message,
    context
) => {
    const { iframe } = context;
    const { token, value } = message;

    setElementHeight(iframe, toNumber(value) ?? 0);

    removeIntegrationSpinner(token);
};
