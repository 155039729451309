import { removeElementAttribute, setElementAttribute } from '../dom';

const lockedAttributeName = 'data-locked';

export const setElementLocked = (element: HTMLElement, locked: boolean) => {
    if (locked) {
        setElementAttribute(element, lockedAttributeName, 'true');
    } else {
        removeElementAttribute(element, lockedAttributeName);
    }
};
