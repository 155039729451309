import { DeepPartial, isPlainObject } from '@walley/language';

export const setElementAttributes = <T extends HTMLElement>(
    element: T,
    attributes: DeepPartial<T>
) => {
    Object.entries(attributes)
        .filter(([_, value]) => value !== undefined)
        .forEach(([key, value]) => {
            if (isPlainObject(value)) {
                Object.assign(
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    element[key as keyof T] as any,
                    value
                );
            } else {
                Object.assign(element, { [key]: value });
            }
        });
};
