import { MessageType } from '@walley/domain';
import { HandleMessageFunction } from './HandleMessageFunction';
import { handleBroadcastMessage } from './handleBroadcastMessage';
import { handleHeightSetMessage } from './handleHeightSetMessaget';
import { handleHideModalMessage } from './handleHideModalMessage';
import { handleMerchantEventMessage } from './handleMerchantEventMessage';
import { handleOldMerchantEventMessage } from './handleOldMerchantEventMessage';
import { handleOnBeforePaymentMessage } from './handleOnBeforePaymentMessage';
import { handleReadyMessage } from './handleReadyMessage';
import { handleRedirectMessage } from './handleRedirectMessage';
import { handleRelayMessage } from './handleRelayMessage';
import { handleShowModalMessage } from './handleShowModalMessage';
import { handleStoreParameterMessage } from './handleStoreParameterMessage';

const handleNoopMessage: HandleMessageFunction = () => undefined;

export const getHandleMessageFunction = (
    type: MessageType
): HandleMessageFunction | undefined => {
    switch (type) {
        case MessageType.Relay:
            return handleRelayMessage;
        case MessageType.Broadcast:
            return handleBroadcastMessage;
        case MessageType.ShowModal:
            return handleShowModalMessage;
        case MessageType.HideModal:
            return handleHideModalMessage;
        case MessageType.HeightSet:
            return handleHeightSetMessage;
        case MessageType.MerchantEvent:
            return handleMerchantEventMessage;
        case MessageType.CustomerUpdated:
        case MessageType.Locked:
        case MessageType.Unlocked:
        case MessageType.Resumed:
        case MessageType.ShippingUpdated:
        case MessageType.PurchaseCompleted:
        case MessageType.PaymentAborted:
        case MessageType.CrmUpdated:
        case MessageType.MerchantValidationFailed:
        case MessageType.Metric:
            return handleOldMerchantEventMessage;
        case MessageType.StoreParameter:
            return handleStoreParameterMessage;
        case MessageType.Redirect:
            return handleRedirectMessage;
        case MessageType.OnBeforePayment:
            return handleOnBeforePaymentMessage;
        case MessageType.LanguagePref:
            return handleNoopMessage; // To avaid logging of unhandled message, only used for paylink
        case MessageType.Ready:
            return handleReadyMessage;
        default:
            return undefined;
    }
};
