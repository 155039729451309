import { LogConfig, LogContext } from '@walley/domain';

export const createLogContext = (config: LogConfig): LogContext => ({
    enricher: undefined,
    apiHostOverrides: {},
    sequenceNumber: 0,
    duplicateMessageCount: 0,
    lastLogString: '',
    lastLogToken: undefined,
    lastLogTimestamp: 0,
    config,
});
