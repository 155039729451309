import { isObject } from '../object';
import { isNonWhitespaceString } from '../string';
import { jsonStringify } from '../utils';

const appendUriQueryValue = <T extends string>(
    target: T,
    key: string,
    value: unknown
): T => {
    const serializedValue = isObject(value)
        ? jsonStringify(value)
        : value?.toString();

    const [path, query] = (target ?? '').split('?');

    const separator = query ? '&' : '';

    return isNonWhitespaceString(serializedValue)
        ? (`${path ?? ''}?${query ?? ''}${separator}${encodeURIComponent(
              key
          )}=${encodeURIComponent(serializedValue)}` as T)
        : target;
};

export const appendUriQuery = <T extends string>(
    target: T,
    query: Record<string, unknown>
): T =>
    Object.entries(query).reduce<T>(
        (result, [key, value]) => appendUriQueryValue(result, key, value),
        target
    );
