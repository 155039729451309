import { isString } from '@walley/language';
import { toCountry } from '../locale';
import { PublicToken } from './PublicToken';
import { Token } from './Token';
import { WidgetToken } from './WidgetToken';

const tokenPattern = /\b(public|widget)-([A-Z]{2})-(\w+)/;

export const parseToken = (token: Token | string | undefined) => {
    if (!isString(token)) {
        return {};
    }

    const [result, type, countryCode, ref] = token.match(tokenPattern) ?? [];

    return result
        ? {
              token: result as Token,
              publicToken:
                  type === 'public' ? (result as PublicToken) : undefined,
              widgetToken:
                  type === 'widget' ? (result as WidgetToken) : undefined,
              type,
              country: toCountry(countryCode),
              ref,
          }
        : {};
};
