import { MessageType, Token } from '@walley/domain';
import {
    SendMessageToIntegrationIframeContext,
    sendMessageToIntegrationIframes,
} from './sendMessageToIntegrationIframes';

export const sendResume = (
    token: Token | undefined,
    context: SendMessageToIntegrationIframeContext
) =>
    sendMessageToIntegrationIframes(
        { token, type: MessageType.Resume },
        context
    );
