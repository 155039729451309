import {
    IntegrationName,
    WalleyCheckoutState,
    createIntegrationId,
} from '@walley/domain';

export const createWalleyCheckoutState = (
    name: IntegrationName
): WalleyCheckoutState =>
    ({
        initiated: false,
        id: createIntegrationId(name),
    }) satisfies WalleyCheckoutState;
