import { isString } from '../string';
import { jsonParse } from '../utils';
import { DeepPartialObject } from './DeepPartialObject';
import { isObject } from './isObject';

export const toObject = <T = object>(
    value: unknown
): DeepPartialObject<T> | undefined => {
    if (isObject(value)) {
        return value as DeepPartialObject<T>;
    }

    if (isString(value)) {
        const parsed = jsonParse<T>(value);

        if (isObject(parsed)) {
            return parsed;
        }
    }

    return undefined;
};
