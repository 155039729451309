import { isString } from '@walley/language';

export const isStorageAvailable = (
    storage: Storage | 'localStorage' | 'sessionStorage',
    parent: Window & typeof globalThis = window
): boolean => {
    try {
        const target = isString(storage) ? parent[storage] : storage;
        const test = '__test';
        target.setItem(test, test);
        const result = target.getItem(test);
        target.removeItem(test);
        return result === test;
    } catch {
        return false;
    }
};
