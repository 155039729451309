import { Message } from '@walley/domain';
import { toObject } from '@walley/language';
import { StorageValue } from '../../storage/StorageValue';
import { getWalleyStorage } from '../getWalleyStorage';
import { setWalleyStorage } from '../setWalleyStorage';

export const handleStoreParameterMessage = (message: Message) => {
    const walleyStorage = getWalleyStorage();
    const payload = toObject<{
        key: string;
        value: StorageValue;
    }>(message.value);

    if (payload?.key) {
        setWalleyStorage({
            ...walleyStorage,
            [payload.key]: payload.value,
        });
    }
};
