import { isNonEmpty } from '@walley/language';

export const getAttribute = (element: HTMLElement, ...names: string[]) =>
    element
        ? names?.reduce<string | undefined>(
              (value, name) =>
                  isNonEmpty(value)
                      ? value.toString()
                      : element.getAttribute(name) || undefined,
              undefined
          )
        : undefined;
