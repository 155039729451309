import { isDefined, jsonStringify } from '@walley/language';
import { Fetch } from './Fetch';
import { HttpPostOptions } from './HttpPostOptions';
import { http } from './http';

export const httpPost = <
    T extends Record<string, unknown> = Record<string, unknown>,
>(
    options: HttpPostOptions<T>,
    fetch?: Fetch
) => {
    const defaultHeaders: HeadersInit = {};

    if (isDefined(options.body)) {
        defaultHeaders['Content-Type'] = 'application/json';
    }

    return http(
        {
            ...options,
            headers: {
                ...defaultHeaders,
                ...options.headers,
            },
            method: 'POST',
            body: jsonStringify(options.body) ?? '{}',
        },
        fetch
    );
};
