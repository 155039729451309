import {
    Message,
    MessageType,
    MessageValue,
    toIntegrationId,
    toToken,
} from '@walley/domain';
import { isObject, isString } from '@walley/language';

export const parseMessage = (message: unknown): Message | undefined => {
    if (!isObject(message)) {
        return undefined;
    }

    const { type, value } = message;
    const sourceId = toIntegrationId(message.sourceId);
    const targetId = toIntegrationId(message.targetId);
    const token = toToken(message.token);

    if (!isString(type) || !sourceId) {
        return undefined;
    }

    return {
        type: type as MessageType, // To be able to log invaid types we cannot take toEnum
        value: value as MessageValue,
        token: toToken(token),
        sourceId,
        targetId,
    };
};
