export enum MerchantEventType {
    CustomerUpdated = 'CustomerUpdated',
    Locked = 'Locked',
    Unlocked = 'Unlocked',
    Resumed = 'Resumed',
    ShippingUpdated = 'ShippingUpdated',
    PurchaseCompleted = 'PurchaseCompleted',
    PaymentAborted = 'PaymentAborted',
    CrmUpdated = 'CrmUpdated',
    OrderValidationFailed = 'OrderValidationFailed',
    Metric = 'Metric',
}
