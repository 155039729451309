import { toHexadecimal } from '../number/toHexadecimal';

const createRandomNumber = (): number => {
    try {
        return window.crypto.getRandomValues(new Uint32Array(1))[0];
    } catch {
        return Date.now();
    }
};

export const createRandomId = (): string => toHexadecimal(createRandomNumber());
