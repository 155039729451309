import { PaymentMethodName } from '../payment-methods';

export const IntegrationNameUnknown = 'Unknown';
export const IntegrationNameCheckoutV2 = 'CheckoutV2';
export const IntegrationNameWidgetPartPayment = 'WidgetPartPayment';
export const IntegrationNameModal = 'Modal';
export const IntegrationNameSpinner = 'Spinner';
export const IntegrationNameSdk = 'Sdk';
export const IntegrationNameLoader = 'Loader';
export const IntegrationNameB2cHeadless = 'B2cHeadless';
export const IntegrationNameDemo = 'Demo';

export type IntegrationName =
    | typeof IntegrationNameUnknown
    | typeof IntegrationNameCheckoutV2
    | typeof IntegrationNameWidgetPartPayment
    | typeof IntegrationNameModal
    | typeof IntegrationNameSpinner
    | typeof IntegrationNameSdk
    | typeof IntegrationNameLoader
    | typeof IntegrationNameB2cHeadless
    | typeof IntegrationNameDemo
    | `Payment${PaymentMethodName}`;
