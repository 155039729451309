import { Log } from '@walley/domain';
import { InitIntegrationData } from './InitIntegrationData';
import { createIntegrationContent } from './createIntegrationContent';
import { createIntegrationSpinner } from './createIntegrationSpinner';
import { insertIntegrationContent } from './insertIntegrationContent';
import { removeIntegrationSpinner } from './removeIntegrationSpinner';

export const renderCheckout = ({
    data,
    log,
    script,
}: {
    data: InitIntegrationData;
    log: Log;
    script: HTMLScriptElement;
}) => {
    const content = createIntegrationContent(data, log);

    removeIntegrationSpinner(data.token);

    if (!data.widget && !data.headless) {
        insertIntegrationContent(createIntegrationSpinner(content), {
            data,
            script,
        });
    }

    insertIntegrationContent(content, {
        data,
        script,
    });
};
