const missingFor = (target: object) => (key: string) => !(key in target);

const withTarget = (targetName: string) => (key: string) =>
    `${targetName}.${key}`;

export const getUnsupportedFeatures = () =>
    new Array<string>()
        .concat(
            [
                'JSON',
                'postMessage',
                'addEventListener',
                'Intl',
                'fetch',
                'Promise',
                'AbortController',
                'history',
                'location',
                'console',
                'URL',
                'MutationObserver',
            ].filter(missingFor(window))
        )
        .concat(
            [
                'querySelector',
                'querySelectorAll',
                'activeElement',
                'head',
                'body',
            ]
                .filter(missingFor(document))
                .map(withTarget('document'))
        )
        .concat(
            ['origin', 'search', 'href', 'hostname']
                .filter(missingFor(window.location))
                .map(withTarget('location'))
        )
        .concat(
            ['replaceState']
                .filter(missingFor(window.history))
                .map(withTarget('history'))
        )
        .concat(
            ['createObjectURL'] // For swish qr
                .filter(missingFor(window.URL))
                .map(withTarget('URL'))
        )
        .concat(['isArray'].filter(missingFor(Array)).map(withTarget('Array')))
        .concat(
            ['includes']
                .filter(missingFor(Array.prototype))
                .map(withTarget('Array'))
        )
        .concat(
            ['trim', 'includes']
                .filter(missingFor(String.prototype))
                .map(withTarget('String'))
        )
        .concat(window.navigator.userAgent !== 'UNSUPPORTED' ? [] : ['TEST']);
