import { Log } from '@walley/domain';
import { renderCheckout, setElementAttribute } from '@walley/interaction';
import { Uri } from '@walley/language';
import {
    getLoaderScriptAttributes,
    loaderAttributeInitialized,
} from './getLoaderScriptData';
import { loaderScriptAttributesToInitData } from './loaderScriptAttributesToInitData';

export const initLoaderScript = (
    script: HTMLScriptElement,
    clientHost: Uri,
    log: Log
) => {
    const attributes = getLoaderScriptAttributes(script);
    const data = loaderScriptAttributesToInitData(attributes, clientHost);

    if (attributes.initialized) {
        return;
    }

    if (!data?.token) {
        log.info({
            moduleName: 'initLoaderScript',
            messageTemplate: 'Token {PublicToken} is invalid',
            messageTemplateParameters: [attributes.token],
        });
        return;
    }

    renderCheckout({ data, log, script });

    setElementAttribute(script, loaderAttributeInitialized, 'true');
};
