import { Log, Token, WalleyCheckoutApi, WalleyGlobal } from '@walley/domain';
import { sendResume, sendSuspend } from '@walley/interaction';
import { Uri, isFunction } from '@walley/language';
import { sendUpdate } from './sendUpdate';

type CreateWalleyCheckoutApiPayload = {
    clientHost: Uri;
    log: Log;
    walley: WalleyGlobal;
};

export const createWalleyCheckoutApi = ({
    clientHost,
    log,
    walley,
}: CreateWalleyCheckoutApiPayload): WalleyCheckoutApi => {
    const loaderContext = { clientHost, log, walley };

    const update = () => sendUpdate(loaderContext);
    const suspend = (token?: Token) => sendSuspend(token, loaderContext);
    const resume = (token?: Token) => sendResume(token, loaderContext);

    const onBeforePayment = (callback?: () => Promise<void>) => {
        const logSetOnBeforePayment = (message: string) => {
            log.info({
                messageTemplate: `onBeforePayment: ${message}`,
                moduleName: 'index',
                functionName: 'onBeforePayment',
            });
        };

        if (callback && !isFunction(callback)) {
            const message = 'The provided callback must be a function';
            logSetOnBeforePayment(message);
            throw new Error(message);
        }

        logSetOnBeforePayment(
            `Setting callback to ${callback ? 'a function' : 'null'}`
        );

        // eslint-disable-next-line no-underscore-dangle
        walley.checkout._state.onBeforePayment = callback;
    };

    // const beginPayment = ({
    //     publicToken,
    //     paymentMethodName,
    // }: BeginPaymentPayload) =>
    //     sendMessageToIntegrationIframes(
    //         {
    //             type: MessageType.BeginPayment,
    //             token: publicToken,
    //             value: paymentMethodName
    //                 ? jsonStringify({ paymentMethodName })
    //                 : undefined,
    //         },
    //         loaderContext
    //     );

    // const addPaymentMethod = ({
    //     publicToken,
    //     paymentMethodName,
    //     elementId,
    // }: AddPaymentMethodPayload) => {
    //     const element = getElementById(elementId);

    //     if (!element) {
    //         // TODO: Log that element not found
    //         return;
    //     }

    //     const suffix = `module-payment-method-${paymentMethodName}`;

    //     if (getIntegrationIframe(publicToken, suffix)) {
    //         log.debug({
    //             messageTemplate:
    //                 'Payment method iframe with payment method name {Name} already exists, skipping',
    //             messageTemplateParameters: [paymentMethodName],
    //             moduleName: 'loader',
    //             functionName: 'addPaymentMethod',
    //         });
    //         return;
    //     }

    //     const iframe = createIntegrationIframe(
    //         { token: publicToken, clientHost },
    //         `Walley Payment - ${paymentMethodName}`,
    //         {
    //             src: `${clientHost}/module/payment-method/${publicToken}?paymentMethodName=${paymentMethodName}`,
    //         },
    //         suffix
    //     );

    //     element.appendChild(iframe);
    // };

    // const getPaymentMethods = async ({
    //     publicToken,
    // }: GetPaymentMethodsPayload) =>
    //     (
    //         await apiGetPaymentMethods({
    //             apiHost,
    //             publicToken,
    //         })
    //     ).map(p => ({
    //         paymentName: p.paymentMethodName,
    //     }));

    return {
        suspend,
        resume,
        update,
        // beginPayment,
        // addPaymentMethod,
        // getPaymentMethods,
        onBeforePayment,
    };
};
