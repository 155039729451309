import { DeepPartial } from './DeepPartial';
import { DeepPartialObject } from './DeepPartialObject';
import { isEmptyObject } from './isEmptyObject';
import { isEmptyValue } from './isEmptyValue';
import { isNonEmptyValue } from './isNonEmptyValue';
import { isPlainObject } from './isPlainObject';

export const withoutEmptyValues = <T>(
    obj: T | undefined
): DeepPartial<T> | undefined => {
    if (isEmptyValue(obj)) {
        return undefined;
    }

    if (!isPlainObject(obj)) {
        return obj as DeepPartial<T>;
    }

    type KeyType = keyof typeof obj;

    const result = Object.keys(obj).reduce(
        (acc: DeepPartialObject<T>, k: KeyType) => {
            const property = obj[k as KeyType] as unknown;
            const propertyValue = isPlainObject(property)
                ? withoutEmptyValues(property)
                : property;

            if (isNonEmptyValue(propertyValue)) {
                return { ...acc, [k]: propertyValue };
            }
            return acc;
        },
        {} as DeepPartialObject<T>
    ) as DeepPartial<T>;

    return !isEmptyObject(result) ? result : undefined;
};
