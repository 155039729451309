import { httpPost } from '../http';
import { ApiPostPayload } from './ApiPostPayload';
import { getApiRequestOptions } from './getApiRequestOptions';

export const apiPostRaw = async <TRequest extends Record<string, unknown>>(
    payload: Omit<ApiPostPayload<TRequest>, 'setCheckoutState'>
) =>
    httpPost({
        ...getApiRequestOptions(payload),
        body: payload.body,
    });
