export const getDecimalSeparator = (
    locale: string | undefined,
    fallback: string
) => {
    try {
        return (
            Intl.NumberFormat(locale)
                .formatToParts(1.1)
                .find(part => part.type === 'decimal')?.value ?? fallback
        );
    } catch {
        return fallback;
    }
};
