import { getElementById, insertElement } from '../dom';
import { InitIntegrationData } from './InitIntegrationData';

export const insertIntegrationContent = (
    content: HTMLElement,
    { data, script }: { data: InitIntegrationData; script: HTMLScriptElement }
) => {
    const { containerId } = data;

    const container = containerId ? getElementById(containerId) : undefined;

    if (container) {
        insertElement(content, container);
        return;
    }

    if (script.parentNode) {
        script.parentNode.insertBefore(content, script);
        return;
    }

    insertElement(content, document.body);
};
