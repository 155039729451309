import { isString } from '@walley/language';
import { IntegrationId } from './IntegrationId';
import { IntegrationName } from './IntegrationName';

export const toIntegrationId = (
    value: unknown,
    integrationName?: IntegrationName
): IntegrationId | undefined => {
    if (!isString(value)) {
        return undefined;
    }

    const [_, name, id] = value.match(/^(\w+)-(\w+)$/) ?? [];

    if (!name || !id) {
        return undefined;
    }

    if (integrationName && name !== integrationName) {
        return undefined;
    }

    return value as IntegrationId;
};
