import { IntegrationName, Token, createIntegrationId } from '@walley/domain';
import {
    DeepPartial,
    Uri,
    appendUriQuery,
    callOptionalFunction,
} from '@walley/language';
import { createIframeElement } from '../dom';
import { IFRAME_ID_PREFIX } from './constants';

export const createIntegrationIframe = (
    token: Token | undefined,
    title: string,
    attributes: DeepPartial<HTMLIFrameElement>,
    name: IntegrationName,
    src: Uri
): HTMLIFrameElement => {
    const integrationId = createIntegrationId(name);
    const iframe = createIframeElement({
        title,
        className: IFRAME_ID_PREFIX,
        width: '100%',
        height: '0',
        style: {
            border: 'none',
            background: 'transparent',
            width: '1em',
            minWidth: '100%',
            overflow: 'auto hidden',
            display: 'block',
        },
        src: appendUriQuery(src, { integrationId }),
        // Obsolete but seemingly necessary scrolling and frameBorder below...
        scrolling: 'no',
        frameBorder: '0',
        ...attributes,
        dataset: {
            ...attributes?.dataset,
            walley: 'true',
            token,
            id: integrationId,
            name,
        },
        onload: (event: Event) => {
            callOptionalFunction(
                attributes?.onload as (event: Event) => void,
                event
            );
        },
    });

    return iframe;
};
