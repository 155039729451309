import { isErrorEvent } from '../event';
import { toPlainObject } from '../object';
import { isString } from '../string';
import { jsonStringify } from '../utils';
import { ErrorLike } from './ErrorLike';
import { isErrorLike } from './isErrorLike';

export const toErrorLike = (error: unknown): ErrorLike | undefined => {
    if (!error) {
        return undefined;
    }

    if (isErrorLike(error)) {
        return error;
    }

    if (isErrorEvent(error) && isErrorLike(error.error)) {
        return error.error;
    }

    if (isString(error)) {
        return {
            message: error,
            name: 'ErrorString',
        };
    }

    const plainError = toPlainObject(error);

    return {
        message: 'An unexpected error object',
        name: 'UnexpectedError',
        ...plainError,
        data: plainError ? undefined : jsonStringify(error),
    };
};
