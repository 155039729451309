import { DeepPartial } from '@walley/language';
import { setElementAttributes } from './setElementAttributes';

export const createElement = <K extends keyof HTMLElementTagNameMap>(
    type: K,
    attributes?: DeepPartial<HTMLElementTagNameMap[K]>
): HTMLElementTagNameMap[K] => {
    const element = document.createElement(type);

    if (attributes) {
        setElementAttributes(element, attributes);
    }

    return element;
};
