import { MessageType, Token } from '@walley/domain';
import {
    SendMessageToIntegrationIframeContext,
    sendMessageToIntegrationIframes,
} from './sendMessageToIntegrationIframes';

export const sendSuspend = (
    token: Token | undefined,
    context: SendMessageToIntegrationIframeContext
) =>
    sendMessageToIntegrationIframes(
        {
            token,
            type: MessageType.Suspend,
        },
        context
    );
