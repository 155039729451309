import { StackFrame, fromError } from 'stacktrace-js';
import { renderMessageTemplateParameter } from './renderMessageTemplateParameter';

const formatStackFrame = (frame: StackFrame): string => {
    const functionName =
        frame.getFunctionName()?.trim() || (frame.getIsEval() ? '[eval]' : '');
    const fileName =
        frame.getFileName()?.trim() || (frame.getIsNative() ? '[native]' : '');
    const lineNumber = frame.getLineNumber() || 0;
    const columnNumber = frame.getColumnNumber() || 0;

    return `at ${functionName || '?'}${
        fileName
            ? ` (${fileName}${
                  lineNumber ? `:${lineNumber}:${columnNumber}` : ''
              })`
            : ''
    }`;
};

// Shoud we handle loader and checkout differently? Do we want to include this in loader script at all? (pass in a function callback to createLog instead)
export const resolveErrorStack = async (
    exception: Error
): Promise<string | undefined> => {
    try {
        const stackFrames = await fromError(exception);
        const resolvedStack = stackFrames
            .map(frame => formatStackFrame(frame))
            .join('\n');
        return (
            resolvedStack +
            (exception.stack !== resolvedStack
                ? `\nOriginal stack:\n${renderMessageTemplateParameter(
                      exception.stack,
                      false
                  )}`
                : '')
        );
    } catch (_error) {
        // do nothing
    }

    return exception.stack || undefined; // Ensure undefined and not null or empty string;
};
