import {
    DeepPartial,
    Uri,
    concatUri,
    ensureUriEndingSlash,
} from '@walley/language';
import { IntegrationOverrides } from './IntegrationOverrides';

export const getClientUri = (
    clientHost: Uri,
    path: string,
    overrides: DeepPartial<IntegrationOverrides> | undefined
): Uri =>
    ensureUriEndingSlash(
        (overrides?.clientUris ? overrides.clientUris[path] : undefined) ||
            `${concatUri(clientHost, path)}`
    );
