import { toObject, toUri } from '@walley/language';
import { redirect } from '../../dom/redirect';
import { HandleMessageFunction } from './HandleMessageFunction';

export const handleRedirectMessage: HandleMessageFunction = (
    { token, value },
    { log }
) => {
    const options = toObject<{ url: string }>(value);

    const uri = toUri(options?.url);

    if (!uri) {
        return;
    }

    log.info({
        messageTemplate: 'Redirecting to {RequestUri}',
        messageTemplateParameters: [uri],
        moduleName: 'handleRedirectMessage',
        properties: { publicToken: token },
    });

    redirect(uri);
};
