import { Message } from '@walley/domain';
import { HandleMessageFunction } from './HandleMessageFunction';
import { sendMessageToIntegrationIframes } from './sendMessageToIntegrationIframes';

export const handleBroadcastMessage: HandleMessageFunction = (
    message,
    context
) =>
    sendMessageToIntegrationIframes(
        {
            ...(message.value as Message),
            token: undefined,
            sourceId: message.sourceId,
        },
        context
    );
