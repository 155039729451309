import { IntegrationNameModal } from '@walley/domain';
import { DeepPartial, Uri } from '@walley/language';
import { createDivElement } from '../dom';
import { InitIntegrationModalData } from './InitIntegrationModalData';
import { createIntegrationIframe } from './createIntegrationIframe';
import { getIntegrationUri } from './getIntegrationUri';
import { getMaxZIndex } from './getMaxZIndex';
import { modalOpenClassName } from './modalOpenClassName';

export const appendIntegrationModalIframe = (
    clientHost: Uri,
    data: DeepPartial<InitIntegrationModalData>,
    onload: () => void
): HTMLIFrameElement => {
    const initData = {
        clientHost,
        ...data,
    };

    const iframe = createIntegrationIframe(
        data.token,
        `Walley Modal - ${data.widget}`,
        {
            onload,
            style: {
                position: 'fixed',
                top: '0',
                left: '0',
                height: '100%',
                width: '100%',
                zIndex: getMaxZIndex(),
                border: 'none',
            },
            dataset: {
                modal: data.widget,
            },
        },
        IntegrationNameModal,
        getIntegrationUri(initData)
    );

    const wrapper = createDivElement();
    wrapper.appendChild(iframe);
    document.body.appendChild(wrapper);
    document.body.classList.add(modalOpenClassName);

    return iframe;
};
