/* eslint-disable no-underscore-dangle */
import { IntegrationId, Message, toToken } from '@walley/domain';
import { getUriOrigin, toErrorLike } from '@walley/language';
import { sendMessage } from '../../message/sendMessage';
import { getIntegrationIframes } from '../getIntegrationIframes';
import { HandleMessageContext } from './HandleMessageContext';

const moduleName = 'sendMessageToIntegrationIframe';

export type SendMessageToIntegrationIframeContext = Pick<
    HandleMessageContext,
    'log' | 'walley'
>;

export const sendMessageToIntegrationIframes = (
    message: Omit<Message, 'sourceId'> & { sourceId?: IntegrationId },
    context: SendMessageToIntegrationIframeContext
) => {
    const { token, type, value, sourceId: messageSourceId, targetId } = message;
    const { log, walley } = context;

    getIntegrationIframes({ token }).forEach(iframe => {
        const iframeToken = toToken(iframe.dataset.token);

        if (
            (token && iframeToken !== token) ||
            !iframe.src ||
            iframe.src === 'about:blank' ||
            !iframe.contentWindow ||
            iframe.dataset.id === messageSourceId
        ) {
            return;
        }

        const targetWindow = iframe.contentWindow;
        const sourceId = messageSourceId || walley.checkout._state.id;
        const integrationId = iframe.dataset.id as IntegrationId | undefined;

        if (iframe.dataset.ready) {
            try {
                log.debug({
                    messageTemplate:
                        'Sending message {MessageType} with value {Message} to iframe {Name}',
                    messageTemplateParameters: [type, value, integrationId],
                    moduleName,
                    properties: {
                        publicToken: iframeToken,
                    },
                    allowDuplicates: true,
                });

                sendMessage({
                    type,
                    token: iframeToken,
                    sourceId,
                    targetId,
                    value,
                    targetOrigin: getUriOrigin(iframe.src) ?? '*',
                    targetWindow,
                });
            } catch (exception) {
                log.warn({
                    messageTemplate:
                        'Failed to send message {MessageType} to iframe: {ExceptionMessage}',
                    messageTemplateParameters: [
                        type,
                        toErrorLike(exception)?.message,
                    ],
                    properties: {
                        publicToken: iframeToken,
                    },
                    moduleName,
                });
            }
        } else if (integrationId) {
            log.debug({
                messageTemplate:
                    'Queue message {MessageType} with value {Message} to iframe {Name}',
                messageTemplateParameters: [type, value, integrationId],
                moduleName,
                properties: {
                    publicToken: iframeToken,
                },
                allowDuplicates: true,
            });

            // eslint-disable-next-line no-multi-assign
            const queuedMessages = (walley.checkout._state.queuedMessages =
                walley.checkout._state.queuedMessages ?? {});

            if (!(integrationId in queuedMessages)) {
                queuedMessages[integrationId] = [];
            }

            queuedMessages[integrationId].push({
                ...message,
                targetId: integrationId,
                sourceId,
            });
        }
    });
};
