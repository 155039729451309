import { Message } from '@walley/domain';
import { HandleMessageFunction } from './HandleMessageFunction';
import { sendMessageToIntegrationIframes } from './sendMessageToIntegrationIframes';

export const handleRelayMessage: HandleMessageFunction = (message, context) => {
    if (!message.token) return;

    sendMessageToIntegrationIframes(
        {
            ...(message.value as Message),
            sourceId: message.sourceId,
            token: message.token,
        },
        context
    );
};
