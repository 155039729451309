/* eslint-disable no-underscore-dangle */

import { toToken } from '@walley/domain';
import {
    createLog,
    isBrowserSupported,
    registerIntegrationErrorHandler,
    registerIntegrationMessageHandler,
} from '@walley/interaction';
import 'unfetch/polyfill';
import { createCollectorGlobal } from './helpers/createCollectorGlobal';
import { createWalleyGlobal } from './helpers/createWalleyGlobal';
import { getAllLoaderScripts } from './helpers/getAllLoaderScripts';
import { getConfigOrThrowException } from './helpers/getConfigOrThrowException';
import { getTokenAttribute } from './helpers/getTokenAttribute';
import { initLoaderScript } from './helpers/initLoaderScript';
import { isLoaderScriptSrc } from './helpers/isLoaderScriptSrc';

const { apiHost, clientHost, commit, logLevelClient, logLevelServer, version } =
    getConfigOrThrowException();

const log = createLog({
    sourceSystem: 'Walley.Checkout.Loader',
    apiHost,
    commit,
    logLevelClient,
    logLevelServer,
    version,
    console,
});

const init = () =>
    getAllLoaderScripts()
        .reverse()
        .forEach(script => initLoaderScript(script, clientHost, log));

const walley = createWalleyGlobal({ version, clientHost, log });
createCollectorGlobal({ clientHost, log, init, walley });
const { checkout } = walley;

if (!checkout._state.initiated && isBrowserSupported()) {
    registerIntegrationMessageHandler({
        walley,
        clientHost,
        log,
    });
    registerIntegrationErrorHandler({
        walley,
        log,
        isInternalScript: isLoaderScriptSrc,
        logPropertiesEnricher: () => ({
            publicToken: getAllLoaderScripts()
                .map(script => toToken(getTokenAttribute(script)))
                .filter(token => token)
                .join(' or '),
            ...checkout._state.activeEvent,
        }),
    });
    checkout._state.initiated = true;
}

init();
