import {
    IntegrationName,
    IntegrationNameCheckoutV2,
    IntegrationNameWidgetPartPayment,
    Log,
    createIntegrationId,
} from '@walley/domain';
import { isNonEmptyArray, jsonStringify } from '@walley/language';
import { createDivElement, setElementHeight } from '../dom';
import { InitIntegrationData } from './InitIntegrationData';
import { IFRAME_DEFAULT_HEIGHT, PADDING } from './constants';
import { createIntegrationIframe } from './createIntegrationIframe';
import { getIntegrationUri } from './getIntegrationUri';
import { getUnsupportedFeatures } from './getUnsupportedFeatures';
import { removeIntegrationSpinner } from './removeIntegrationSpinner';

// TODO: Move padding=none to query string and let application handle it?
const noPaddingCssText = () =>
    ` margin-left:-${PADDING}px;margin-right:-${PADDING}px;min-width:calc(100% + ${
        2 * PADDING
    }px);`;

const getIntegrationName = (data: InitIntegrationData): IntegrationName =>
    data.widget ? IntegrationNameWidgetPartPayment : IntegrationNameCheckoutV2;

const createIframe = (data: InitIntegrationData) => {
    const iframeElement = createIntegrationIframe(
        data.token,
        'Walley Checkout',
        {
            onload: () => {
                removeIntegrationSpinner(data.token);
            },
        },
        getIntegrationName(data),
        getIntegrationUri(data)
    );

    if (!data.widget && !data.headless) {
        setElementHeight(iframeElement, IFRAME_DEFAULT_HEIGHT);
    }

    if (!data.padding) {
        iframeElement.style.cssText += noPaddingCssText();
    }

    return iframeElement;
};

const createNotSupportedDiv = (data: InitIntegrationData) => {
    const div = createDivElement({
        style: { padding: `${PADDING}px` },
        textContent:
            'Unfortunately your browser is not supported. Try switching to a more modern browser such as Google Chrome, Microsoft Edge or Mozilla Firefox.',
        dataset: {
            walley: 'true',
            token: data.token,
            id: createIntegrationId(getIntegrationName(data)),
        },
    });

    if (data.widget) {
        div.style.display = 'none';
    }

    if (!data.padding) {
        div.style.cssText += noPaddingCssText();
    }

    return div;
};

const moduleName = 'createIntegrationContent';

export const createIntegrationContent = (
    data: InitIntegrationData,
    log: Log
): HTMLElement => {
    const unsupportedFeatures = getUnsupportedFeatures();

    if (isNonEmptyArray(unsupportedFeatures)) {
        log.warn({
            messageTemplate: 'Browser not supported',
            properties: {
                name: unsupportedFeatures[0],
                customData: unsupportedFeatures.join(', '),
                publicToken: data.token,
            },
            moduleName,
        });

        return createNotSupportedDiv(data);
    }

    const iframe = createIframe(data);

    log.info({
        messageTemplate: 'Loading instance {Name}',
        messageTemplateParameters: [iframe.dataset.id],
        properties: {
            customData: jsonStringify(data),
            publicToken: data.token,
        },
        moduleName,
    });

    if (data.token && data.overrides?.apiHost) {
        log.overrideApiHost(data.token, data.overrides.apiHost);
    }

    return iframe;
};
