export class HttpError extends Error {
    constructor(
        message: string,
        public httpMethod: string,
        public requestUri: string,
        public statusCode: number,
        public logged?: boolean,
        public connectionError?: Error
    ) {
        super(message);
        this.name = 'HttpError';
        // Set the prototype explicitly.
        Object.setPrototypeOf(this, HttpError.prototype);
    }
}
