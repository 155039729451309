import { ClientStorage } from './ClientStorage';
import { getStorage } from './getStorage';
import { removeStorageItem } from './removeStorageItem';

export const setStorageItem = (
    key: string,
    value: string | undefined,
    storage?: ClientStorage
) => {
    if (value) {
        getStorage(storage).setItem(key, value);
    } else {
        removeStorageItem(key, storage);
    }
};
