export const IFRAME_ID_PREFIX = 'collector-checkout-iframe';

export const VARIANT_B2C = 'b2c';
export const VARIANT_B2B = 'b2b';
export const VARIANT_WIDGET = 'widget';

export const PADDING = 20;
export const IFRAME_DEFAULT_HEIGHT = 240;

export const SPINNER_SUFFIX = 'spinner';
export const ANIMATION_DELAY = 400;

export const EVENT_PREFIXES = ['collectorCheckout', 'walleyCheckout'];

export const WALLEY_STORAGE_KEY = 'walley';
