import { CollectorCheckoutApi, Log, Token, WalleyGlobal } from '@walley/domain';
import { sendResume, sendSuspend } from '@walley/interaction';
import { Uri } from '@walley/language';
import { sendUpdate } from './sendUpdate';

type CreateCollectorCheckoutApiPayload = {
    clientHost: Uri;
    log: Log;
    walley: WalleyGlobal;
    init: () => void;
};

export const createCollectorCheckoutApi = ({
    clientHost,
    log,
    walley,
    init,
}: CreateCollectorCheckoutApiPayload): CollectorCheckoutApi => {
    const loaderContext = { clientHost, log, walley };

    const update = () => sendUpdate(loaderContext);
    const suspend = (token?: Token) => sendSuspend(token, loaderContext);
    const resume = (token?: Token) => sendResume(token, loaderContext);

    return {
        init,
        suspend,
        resume,
        update,
    };
};
