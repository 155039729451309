import { IntegrationNameSpinner, Token } from '@walley/domain';
import { querySelector } from '../dom';
import { getIntegrationSelector } from './getIntegrationSelector';

export const removeIntegrationSpinner = (token?: Token) =>
    (token
        ? querySelector(
              getIntegrationSelector('div', {
                  token,
                  integrationName: IntegrationNameSpinner,
              })
          )
        : undefined
    )?.remove();
