import { ClientStorage } from './ClientStorage';

export class MemoryStorage implements ClientStorage {
    private storage: Record<string, string>;

    constructor(initial?: Record<string, string>) {
        this.storage = initial ?? {};
    }

    getItem(key: string) {
        return this.storage[key] ?? null;
    }

    setItem(key: string, value: string) {
        this.storage[key] = value;
    }

    removeItem(key: string) {
        delete this.storage[key];
    }

    key(index: number) {
        return this.keys[index];
    }

    clear() {
        this.keys.forEach(key => this.removeItem(key));
    }

    get length() {
        return this.keys.length;
    }

    private get keys() {
        return Object.keys(this.storage);
    }
}
