import { normalizeLogProperty } from './normalizeLogProperty';

export const normalizeLogProperties = <T extends Record<string, unknown>>(
    properties: T | undefined
): T | undefined => {
    return properties
        ? Object.entries(properties).reduce(
              (result, [key, value]) => ({
                  ...result,
                  [key]: normalizeLogProperty(value),
              }),
              {} as T
          )
        : undefined;
};
