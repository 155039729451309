import { Fetch } from './Fetch';
import { HttpError } from './HttpError';
import { HttpOptions } from './HttpOptions';

const defaultTimeout = 30_000;
const defaultFetch: Fetch =
    'fetch' in window ? window.fetch : () => Promise.reject();

declare global {
    interface RequestInit {
        timeout?: number;
    }
}

export const http = async (
    {
        timeout: optionTimeout,
        uri,
        method,
        body,
        headers,
        keepalive,
    }: HttpOptions,
    fetch: Fetch = defaultFetch
) => {
    const controller = new AbortController();

    const timeout = optionTimeout ?? defaultTimeout;

    const id = setTimeout(() => {
        controller.abort(
            new HttpError(
                `Request timed out after ${timeout} ms`,
                method,
                uri,
                0
            )
        );
    }, timeout);

    const result = await fetch(uri, {
        method,
        body,
        headers,
        keepalive,
        signal: controller.signal,
        timeout,
    });

    clearTimeout(id);

    return result;
};
