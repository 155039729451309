// export const LogLevelDebug = 'debug';
// export const LogLevelInformation = 'information';
// export const LogLevelWarning = 'warning';
// export const LogLevelError = 'error';
// export const LogLevelFatal = 'fatal';

// export type LogLevel =
//     | typeof LogLevelDebug
//     | typeof LogLevelInformation
//     | typeof LogLevelWarning
//     | typeof LogLevelError
//     | typeof LogLevelFatal;

export enum LogLevel {
    Debug = 'debug',
    Information = 'information',
    Warning = 'warning',
    Error = 'error',
    Fatal = 'fatal',
}
