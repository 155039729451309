import { renderMessageTemplateParameter } from './renderMessageTemplateParameter';

export const renderMessageTemplate = (
    messageTemplate: string,
    messageTemplateParameters?: unknown[]
): string => {
    const renderedMessageTemplate = renderMessageTemplateParameter(
        messageTemplate,
        false
    );

    return messageTemplateParameters
        ? messageTemplateParameters.reduce<string>(
              (renderedMessage, messageTemplateParameter) =>
                  renderedMessage.replace(
                      /\{\w+\}/,
                      renderMessageTemplateParameter(messageTemplateParameter)
                  ),
              renderedMessageTemplate
          )
        : renderedMessageTemplate;
};
