/* eslint-disable no-underscore-dangle */
import {
    IntegrationNameLoader,
    Log,
    Version,
    WalleyGlobal,
} from '@walley/domain';
import { Uri } from '@walley/language';
import { createWalleyCheckoutApi } from './createWalleyCheckoutApi';
import { createWalleyCheckoutState } from './createWalleyCheckoutState';

type Context = {
    version: Version;
    clientHost: Uri;
    log: Log;
};

export const createWalleyGlobal = ({
    version,
    clientHost,
    log,
}: Context): WalleyGlobal => {
    const walley = window.walley ?? {};
    const loader = walley.loader ?? {
        version,
    };

    const checkout = walley.checkout ?? {};
    checkout.api =
        walley.checkout?.api ??
        createWalleyCheckoutApi({ clientHost, log, walley });
    checkout._state =
        checkout._state ?? createWalleyCheckoutState(IntegrationNameLoader);

    walley.loader = loader;
    walley.checkout = checkout;
    window.walley = walley;

    return walley;
};
