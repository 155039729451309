import { CollectorGlobal, Log, WalleyGlobal } from '@walley/domain';
import { Uri } from '@walley/language';
import { createCollectorCheckoutApi } from './createCollectorCheckoutApi';

type Context = {
    clientHost: Uri;
    log: Log;
    init: () => void;
    walley: WalleyGlobal;
};

export const createCollectorGlobal = ({
    clientHost,
    log,
    init,
    walley,
}: Context): CollectorGlobal => {
    const collector = window.collector ?? {};
    const checkout = collector.checkout ?? {};
    checkout.api =
        collector.checkout?.api ??
        createCollectorCheckoutApi({
            clientHost,
            log,
            walley,
            init,
        });

    collector.checkout = checkout;
    window.collector = collector;

    return collector;
};
