import {
    isArray,
    isDate,
    isEmptyString,
    isEvent,
    isPlainObject,
    isString,
    jsonStringify,
} from '@walley/language';

export const renderMessageTemplateParameter = (
    value: unknown,
    quoteString = true
): string => {
    if (value === undefined) {
        return '[undefined]';
    }

    if (value === null) {
        return '[null]';
    }

    if (isEmptyString(value)) {
        return '[empty string]';
    }

    if (isString(value)) {
        return quoteString ? `"${value}"` : value;
    }

    if (isDate(value)) {
        return value.toISOString();
    }

    if (isEvent(value)) {
        return `[Event ${value.type}]`;
    }

    if (isArray(value)) {
        return `[${value
            .map(v => renderMessageTemplateParameter(v))
            .join(', ')}]`;
    }

    if (isPlainObject(value)) {
        return jsonStringify(value);
    }

    return String(value);
};
