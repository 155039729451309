import { IntegrationNameSpinner, createIntegrationId } from '@walley/domain';
import { createDivElement } from '../dom';
import { ANIMATION_DELAY } from './constants';

export const createIntegrationSpinner = (target: HTMLElement) => {
    const height = 'height' in target ? target.height : target.clientHeight;
    const spinner = createDivElement({
        dataset: {
            walley: 'true',
            token: target.dataset.token,
            id: createIntegrationId(IntegrationNameSpinner),
            name: IntegrationNameSpinner,
        },
        style: {
            position: 'relative',
        },
    });
    const inner = createDivElement({
        style: {
            pointerEvents: 'none',
            position: 'absolute',
            width: '100%',
            zIndex: '-1',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            opacity: '.5',
            minHeight: `${height}px`,
            gap: '.5rem',
        },
    });

    const createDot = () =>
        createDivElement({
            style: {
                background: 'black',
                borderRadius: '100%',
                height: '1rem',
                width: '1rem',
                opacity: '.5',
                transition: '.2s',
            },
        });

    const dots = [0, 1, 2].map(() => {
        const dot = createDot();
        inner.append(dot);
        return dot;
    });

    spinner.appendChild(inner);

    let index = -1;

    const animateLoader = (delay: number) => {
        if (spinner.isConnected) {
            const currentDot = dots[index];
            if (currentDot) {
                currentDot.style.opacity = '.5';
            }
            index = (index + 1) % dots.length;
            dots[index].style.opacity = '1';
        }

        if (spinner.isConnected || index === -1) {
            setTimeout(animateLoader, delay || ANIMATION_DELAY);
        }
    };

    animateLoader(ANIMATION_DELAY / 2);

    return spinner;
};
