import { DeepPartial, jsonStringify } from '@walley/language';
import { ClientStorage } from './ClientStorage';
import { StorageData } from './StorageData';
import { setStorageItem } from './setStorageItem';

export const setStorageData = <T extends StorageData>(
    key: string,
    value: T | DeepPartial<T> | undefined,
    storage?: ClientStorage
): void => setStorageItem(key, jsonStringify(value), storage);
