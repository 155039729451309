import { removeWhitespace } from '../string';
import { getDecimalSeparator } from './getDecimalSeparator';

const decimalSeparator = '.';

export const stringToNumber = (value: string, locale: string | undefined) => {
    const cleanValue = removeWhitespace(
        value
            .replace(
                getDecimalSeparator(locale, decimalSeparator),
                decimalSeparator
            )
            .replace('−', '-')
    );

    if (!/^-?\d+(\.\d+)?$/.test(cleanValue)) {
        return undefined;
    }

    return parseFloat(cleanValue);
};
