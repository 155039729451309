import { IntegrationId, IntegrationName, Token } from '@walley/domain';

export type IntegrationSelectorFilter = {
    integrationId?: IntegrationId;
    token?: Token;
    integrationName?: IntegrationName;
};

export const getIntegrationSelector = <K extends keyof HTMLElementTagNameMap>(
    name: K,
    { integrationId, integrationName, token }: IntegrationSelectorFilter
): string =>
    [
        `${name}[data-walley=true]`,
        integrationId && `[data-id=${integrationId}]`,
        token && `[data-token=${token}]`,
        integrationName && `[data-name=${integrationName}]`,
    ]
        .filter(Boolean)
        .join('');
