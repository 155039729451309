import { getIntegrationIframe } from '../getIntegrationIframe';
import { HandleMessageFunction } from './HandleMessageFunction';
import { sendQueuedMessageToIntegrationIframes } from './sendQueuedMessageToIntegrationIframes';

export const handleReadyMessage: HandleMessageFunction = (message, context) => {
    const { sourceId: integrationId } = message;
    const iframe = getIntegrationIframe({ integrationId });
    if (iframe) {
        iframe.dataset.ready = 'true';
    }
    sendQueuedMessageToIntegrationIframes(integrationId, context);
};
