import {
    LogConsole,
    LogLevel,
    LogMessageTemplate,
    isLogLevelAbove,
    renderMessageTemplate,
} from '@walley/domain';

type ClientLogEntry = {
    level: LogLevel;
    exception?: Error;
} & LogMessageTemplate;

type ClientLogConfig = {
    logLevelClient: LogLevel;
    prefix?: string;
    console: LogConsole;
};

export const logClient = (
    logEntry: ClientLogEntry,
    { logLevelClient, prefix, console }: ClientLogConfig
) => {
    const { level, messageTemplate, messageTemplateParameters, exception } =
        logEntry;

    if (!isLogLevelAbove(level, logLevelClient)) {
        return;
    }

    const message = `[${prefix ?? level}] ${renderMessageTemplate(
        messageTemplate,
        messageTemplateParameters
    )}`;

    switch (level) {
        case LogLevel.Fatal:
        case LogLevel.Error:
            console.error(message);
            break;
        case LogLevel.Warning:
            console.warn(message);
            break;
        case LogLevel.Information:
            console.info(message);
            break;
        case LogLevel.Debug:
            console.debug(message);
            break;
        default:
            console.log(message);
            break;
    }

    if (exception && isLogLevelAbove(LogLevel.Debug, logLevelClient)) {
        console.debug('[debug] Logged exception', exception);
    }
};
