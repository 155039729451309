import { MerchantEvent, MerchantEventType, PublicToken } from '@walley/domain';
import { toObject } from '@walley/language';
import { dispatchEvent } from '../../dom';
import { EVENT_PREFIXES } from '../constants';
import { removeIntegrationModalIframe } from '../removeIntegrationModalIframe';
import { setElementLocked } from '../setElementLocked';
import { HandleMessageFunction } from './HandleMessageFunction';

export const handleMerchantEventMessage: HandleMessageFunction = (
    { value, token },
    { iframe, log, walley }
) => {
    const event = toObject<MerchantEvent>(value);

    if (!event?.type) return;

    switch (event.type) {
        case MerchantEventType.Locked:
            setElementLocked(iframe, true);
            break;
        case MerchantEventType.Unlocked:
            setElementLocked(iframe, false);
            break;
        case MerchantEventType.PurchaseCompleted:
        case MerchantEventType.PaymentAborted:
            removeIntegrationModalIframe();
            break;
        default:
            break;
    }

    const { type, payload } = event;

    log.info({
        messageTemplate:
            'Dispatching event {EventType} with payload {CustomData}',
        messageTemplateParameters: [type, payload],
        moduleName: 'handleMerchantEventMessage',
        properties: {
            publicToken: token,
        },
    });

    // eslint-disable-next-line no-underscore-dangle
    walley.checkout._state.activeEvent = {
        publicToken: token as PublicToken,
        eventType: type,
    };

    EVENT_PREFIXES.forEach(prefix => {
        dispatchEvent(prefix + type, { token, payload });
    });

    // eslint-disable-next-line no-underscore-dangle
    walley.checkout._state.activeEvent = undefined;
};
