import { ClientStorage } from './ClientStorage';
import { StorageData } from './StorageData';
import { getStorageItem } from './getStorageItem';

export const getStorageData = <T extends StorageData>(
    key: string,
    storage?: ClientStorage
): T | undefined => {
    const rawData = getStorageItem(key, storage);
    return rawData ? (JSON.parse(rawData) as T) || undefined : undefined;
};
