import { IntegrationId } from '@walley/domain';
import {
    SendMessageToIntegrationIframeContext,
    sendMessageToIntegrationIframes,
} from './sendMessageToIntegrationIframes';

export const sendQueuedMessageToIntegrationIframes = (
    integrationId: IntegrationId,
    context: SendMessageToIntegrationIframeContext
) => {
    // eslint-disable-next-line no-underscore-dangle
    const { queuedMessages } = context.walley.checkout._state;

    if (integrationId && queuedMessages && integrationId in queuedMessages) {
        while (queuedMessages[integrationId].length) {
            const queuedMessage = queuedMessages[integrationId].shift();
            if (queuedMessage?.type) {
                sendMessageToIntegrationIframes(queuedMessage, context);
            }
        }
    }
};
