import { MessageType, toToken } from '@walley/domain';
import {
    SendMessageToIntegrationIframeContext,
    getAttribute,
    sendMessageToIntegrationIframes,
} from '@walley/interaction';
import { isEmpty, toNumber } from '@walley/language';
import { getAllLoaderScripts } from './getAllLoaderScripts';
import { getTokenAttribute } from './getTokenAttribute';

export const sendUpdate = (
    loaderContext: SendMessageToIntegrationIframeContext
) =>
    getAllLoaderScripts().forEach(script => {
        const token = toToken(getTokenAttribute(script));
        const amount = toNumber(getAttribute(script, 'data-amount'));

        if (isEmpty(token) || isEmpty(amount)) {
            return;
        }

        sendMessageToIntegrationIframes(
            {
                token,
                type: MessageType.Update,
                value: {
                    amount,
                },
            },
            loaderContext
        );
    });
