import { isEmptyArray } from '../array';
import { isEmptyPlainObject } from '../object';
import { isEmptyString } from '../string';
import { isDefined } from './isDefined';

export const isEmpty = (value: unknown) =>
    !isDefined(value) ||
    isEmptyString(value) ||
    isEmptyArray(value) ||
    isEmptyPlainObject(value);
