import { IntegrationId, IntegrationName, Token } from '@walley/domain';

export type IntegrationSelectorFilter = {
    integrationId?: IntegrationId;
    token?: Token;
    integrationName?: IntegrationName;
};

export const getIntegrationSelector = <K extends keyof HTMLElementTagNameMap>(
    name: K,
    { integrationId, integrationName, token }: IntegrationSelectorFilter
): string => {
    // TODO Fix let
    let selector = `${name}[data-walley=true]`;

    if (integrationId) {
        selector += `[data-id=${integrationId}]`;
    }

    if (token) {
        selector += `[data-token=${token}]`;
    }

    if (integrationName) {
        selector += `[data-name=${integrationName}]`;
    }

    return selector;
};
