import { callOptionalFunction } from '../function';
import { isDefined } from '../type';

export const arrayFilterNonNullable = <T = unknown>(
    array: (T | undefined | null)[] | undefined,
    predicate?: (item: NonNullable<T>) => boolean
): NonNullable<T>[] =>
    (array?.filter(
        item =>
            isDefined(item) && (callOptionalFunction(predicate, item) ?? true)
    ) as NonNullable<T>[]) ?? [];
