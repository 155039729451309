import { concatUri, withoutEmptyValues } from '@walley/language';
import { ApiDeletePayload } from './ApiDeletePayload';
import { ApiGetPayload } from './ApiGetPayload';
import { ApiPostPayload } from './ApiPostPayload';

export const getApiRequestOptions = ({
    overrides,
    apiHost,
    path,
    timeout,
    publicToken,
    paymentToken,
    isPayLink,
    isWebView,
    keepalive,
}:
    | ApiGetPayload
    | ApiPostPayload<Record<string, unknown>>
    | ApiDeletePayload) => {
    return {
        uri: concatUri(overrides?.apiHost ?? apiHost, path),
        headers: withoutEmptyValues({
            Authorization: publicToken,
            paymenttoken: paymentToken,
            PayLink: isPayLink?.toString(),
            WebView: isWebView?.toString(),
        }) as HeadersInit,
        timeout: overrides?.timeout ?? timeout,
        keepalive,
    };
};
