import { Message, SendMessagePayload } from '@walley/domain';
import { postMessage } from '../dom';

export const sendMessage = ({
    type,
    token,
    value,
    sourceId,
    targetId,
    targetOrigin,
    targetWindow,
}: SendMessagePayload) =>
    postMessage<Message>(
        {
            type,
            sourceId,
            targetId,
            token,
            value,
        },
        targetOrigin,
        targetWindow
    );
