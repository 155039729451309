/* eslint-disable no-underscore-dangle */
import { IntegrationId, Log, LogLevel, WalleyGlobal } from '@walley/domain';
import {
    Uri,
    callOptionalFunction,
    isObject,
    jsonStringify,
} from '@walley/language';
import { parseMessage } from '../message/parseMessage';
import { getIntegrationIframe } from './getIntegrationIframe';
import { isValidOrigin } from './isValidOrigin';
import { getHandleMessageFunction } from './message/getHandleMessageFunction';

type Context = {
    clientHost: Uri;
    log: Log;
    walley: WalleyGlobal;
};

const getDemoIframe = (sourceId: IntegrationId) =>
    sourceId.startsWith('Demo-') ? document.createElement('iframe') : undefined;

export const registerIntegrationMessageHandler = ({
    clientHost,
    log,
    walley,
}: Context) => {
    callOptionalFunction(walley.checkout._state.unregisterMessageHandler);

    const onWindowMessage = (event: MessageEvent) => {
        if (!isObject(event)) {
            return;
        }

        if (!isValidOrigin(event.origin, clientHost)) {
            return;
        }

        const message = parseMessage(event.data);

        if (!message) {
            return;
        }

        const { sourceId, value, type } = message;
        const iframe =
            getDemoIframe(sourceId) ??
            getIntegrationIframe({ integrationId: sourceId });

        const logOnMessage = (messageTemplate: string, logLevel: LogLevel) => {
            log.log(logLevel, {
                messageTemplate,
                messageTemplateParameters: [
                    type,
                    isObject(value) ? jsonStringify(value) : value,
                    sourceId,
                ],
                moduleName: 'registerIntegrationMessageHandler',
                functionName: 'onWindowMessage',
                properties: {
                    publicToken: message.token,
                },
            });
        };

        if (!iframe) {
            logOnMessage(
                'Received {MessageType} with value {Message} from iframe {Name} but iframe could not be found',
                LogLevel.Information
            );
            return;
        }

        logOnMessage(
            'Received {MessageType} with value {Message} from iframe {Name}',
            LogLevel.Debug
        );

        const context = {
            clientHost,
            iframe,
            log,
            walley,
        };

        const handleMessage = getHandleMessageFunction(type);

        if (!handleMessage) {
            logOnMessage(
                'Received unhandled {MessageType} with value {Message} from iframe {Name}',
                LogLevel.Warning
            );
            return;
        }

        handleMessage(message, context);
    };

    window.addEventListener('message', onWindowMessage);

    walley.checkout._state.unregisterMessageHandler = () => {
        window.removeEventListener('message', onWindowMessage);
        walley.checkout._state.unregisterMessageHandler = undefined;
    };
};
