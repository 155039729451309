import { ClientStorage } from './ClientStorage';
import { MemoryStorage } from './MemoryStorage';
import { isStorageAvailable } from './isStorageAvailable';

const clientStorage: ClientStorage = isStorageAvailable('localStorage')
    ? window.localStorage
    : new MemoryStorage();

export const getStorage = (storage?: ClientStorage): ClientStorage =>
    storage ?? clientStorage;
